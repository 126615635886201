.processings-loader {
  flex: 1;
}

.progress-status {
  @include flex-centred;
  white-space: nowrap;
}

.processing_actions {
  display: flex;
  justify-content: center;
  gap: 0.25rem;
}
